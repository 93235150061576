<template lang="pug">
  v-container
    base-navigation-tabs(
      :items="items"
    ) 
    router-view
</template>
<script>
export default {
  name: 'Incubators',
  data () {
    return {
      items: [
        { path: '/incubators', label: 'Manage' },
        { path: '/incubators/rack-eggs', label: 'Loading Eggs' },
        { path: '/incubators/candled-rack-eggs', label: 'Candling Eggs' },
        { path: '/incubators/duck-egg-collections', label: 'Egg Collections' },
        { path: '/incubators/temperatures', label: 'Temperatures' },
      ],
    }
  },
}
</script>